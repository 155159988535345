/* Default background image for desktop */
.background {
  background-image: url(../public/AsianKatha.png);
  background-size: cover;
  background-position: center;
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

/* Media query for mobile devices */
@media (max-width: 768px) {
  .background {
    background-image: url(../public/asiankatham.png); /* Use mobile background image for screens up to 768px width */
  }
}
  
  .card {
    max-width: 90%; /* Adjusted width for smaller screens */
    padding: 10px; /* Adjusted padding for smaller screens */
  }

  .title-holder {
    font-size: 24px; /* Decreased font size for smaller screens */
  }



.card {
  background: rgba(255, 255, 255, 0.8); /* Adding a semi-transparent white background to make the content more readable */
  padding: 20px;
  border-radius: 10px;
  max-width: 80%; /* Adjusted width for better responsiveness */
  text-align: center;
}

.footer {
  margin-top: 20px;
}

.new-katha {
  margin-bottom: 30px;
}

.title-holder {
  margin-top: 50px; /* Increase margin to move "Coming Soon" section further down */
  font-size: 36px; /* Increase font size for "Coming Soon" */
}



  .card {
    max-width: 90%; /* Adjusted width for smaller screens */
    padding: 10px; /* Adjusted padding for smaller screens */
  }

  .title-holder {
    font-size: 24px; /* Decreased font size for smaller screens */
  }

